<template>
  <div class="d-flex align-center flex-wrap">

    <router-link
        v-for="(item , i) in items" :key="i"
        :is="item.route ? 'router-link' : 'span'"
        :to="item.route"
        class="noUnderline"
    >

      <h5 style="font-size: 13px"
          :style="`color : ${!!item.route ? wsATTENTION : wsDARKLIGHT}`"
          :class="[{'font-weight-regular' : !item.route } , {'pointer' : !!item.route} ]"
      >
        {{  item.text  }}

        <v-icon v-if="item.route" :color="wsDARKLIGHT">
          mdi-chevron-right
        </v-icon>

      </h5>

    </router-link>

  </div>
</template>

<script>
export default {
  name: "wsBreadCrumbs",
  props : {
    items : {
      type : Array,
      default() { return [{ text : this.$t('Homepage') , value : '' }] }
    }
  },
}
</script>

<style scoped>

</style>