<template>
  <page-section

      background-color="white"
  >
<!--    :title="$t('documentation.title')"-->
<!--    :subtitle="$t('documentation.text')"-->
    <v-row>
      <!-- Categories-->
      <v-col cols="12" md="3" lg="3" xl="4">
        <v-sheet>

          <div class=" px-6">

            <div v-for="(item,i) in categories" :key="i + '_topic'">
              <v-hover v-slot="{hover}">

                <div  @click="categoryAction(item)"
                      class="d-flex ">
                  <h4 :style="getCategoryStyle(hover, item)"
                      style="font-size: 16px;"
                      class="py-3 linkHover pointer">
                    {{ item.text }}
                  </h4>
                  <v-icon v-if="item.sub_categories.length > 0" :color="hover ? wsATTENTION : wsDARKER">mdi-menu-down</v-icon>
                </div>

              </v-hover>

              <v-expand-transition>

                <div v-if="item.expand" class="d-flex">
                  <v-divider :style="`border-color : ${wsACCENT}`" vertical />
                  <div>
                    <v-hover v-for="(child) in item.sub_categories"
                             :key="child.value"
                             v-slot="{hover}">
                      <div @click="categoryAction(child)" >
                        <h4 :style="getCategoryStyle(hover,child)"
                            class="py-3 pl-4 linkHover pointer font-weight-regular"
                        >
                          {{ child.text }}
                        </h4>
                      </div>
                    </v-hover>
                  </div>
                </div>
              </v-expand-transition>

            </div>

          </div>


        </v-sheet>
      </v-col>
      <!-- Topic OR Topics list -->
      <v-col cols="12" md="9" lg="9" xl="8">

        <ws-bread-crumbs
            v-if="!SM"
            :items="breadcrumbs"
            class="py-6" />
        <v-divider
            :style="`border-color : ${wsDARKLIGHT}`"
            class="mb-6"
        />
        <h1 v-if="selectedCategory.text"
            :style="`color : ${wsDARKER}`"
            :class="[{'px-6' : SM}]"
        >
          {{ selectedCategory.text }}
        </h1>
<!--        <h2 v-if="selectedCategory.text"-->
<!--            :style="`color : ${wsDARKER}`"-->
<!--            class="mt-3"-->
<!--        >-->
<!--          {{ selectedCategory.text }}-->
<!--        </h2>-->

          <!-- Content:  Topic or Topic List -->
          <div class="mt-8"
               :class="[{'px-6' : SM}]"
          >
            <!-- Topic Content  -->
            <div  v-if="topic.uuid" >

              <h3 style="font-size : 16px" :style="`color:${wsDARKER}`" class="pb-4 "> {{ topic.title }} </h3>
              <ws-text-viewer :value="topic.content" is-public />

            </div>

            <!-- Topics List  -->
            <template v-else-if="selectedCategory.value"  >

              <v-hover v-for="(item,i) in selectedCategory.topics"
                       :key="item.value"
              >


                <div @click="openTopic(item.alias)" class="pointer" :class="[{'pt-4' : i > 0}]" >
                  <h3 class="underlineHover mt-5" :style="`color : ${wsDARKER}; font-size : 16px`" > {{ item.text }}</h3>

                  <h5 class="mt-2 font-weight-regular"  :style="`color : ${wsDARKER}; font-size : 16px`" > {{ item.description }}</h5>
                  <h5 class="mt-2" :style="`color: ${wsDARKLIGHT}; font-size : 12px`">{{ $t('Updated') }} : {{ PARSE_DATE(item.date ) }}</h5>
                </div>

              </v-hover>




            </template>

            <div v-else>
              <div>

              </div>
            </div>


            <router-view>

            </router-view>
          </div>

      </v-col>
    </v-row>
  </page-section>
</template>

<script>

import {mapActions} from "vuex";
import wsBreadCrumbs from "@/components/pages/homepage/UI/wsBreadCrumbs";
export default {
  name: "HomeDocumentation",
  components: {
    wsBreadCrumbs
  },

  props : {
    project : {
      type : String,
      default : 'westudy',
    },
    alias : {
      type : String,
    }
  },
  data() {
    return {
      categories : [],
      selectedCategory : {},
      topic : {}
    }
  },
  computed : {
    breadcrumbs() {
      return [
        {
         text :   this.$t('Documentation') , route : this.localeLink('documentation/westudy')
        },
        {
          text :   this.selectedCategory.text
        }
      ]
    }
  },
  watch : {
    project() {
      this.initPage()
    },
    alias(value) {
      if ( value ) {
        this.getTopic(value)
      }
    },
    LANG() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('documentation', [
        'GET_CATEGORIES_PUBLIC',
        'GET_TOPIC_PUBLIC'
    ]),

    returnToCategory() {
      this.topic = {}
      this.$router.push(this.localeLink('documentation/' + this.project ))
    },

    openTopic(alias) {
      this.$router.push(this.localeLink('documentation/' + this.project + '/' + alias))
    },
    async getTopic(alias) {

      let result = await this.GET_TOPIC_PUBLIC(alias)
      if ( !result ) {
        return
      }
      this.topic = result
      return true
    },

    getCategoryStyle(hover,item) {

      if ( hover ) {
        return `color : ${hover ? this.wsATTENTION : this.wsDARKER}`
      }
      if ( JSON.stringify(item) === JSON.stringify(this.selectedCategory) ) {
        return `color : ${this.wsATTENTION}`
      }

      if ( item.sub_categories && item.sub_categories.length > 0 ) {
        let search = item.sub_categories.findIndex( el=> JSON.stringify(el) === JSON.stringify(this.selectedCategory) )
        if ( search !== -1 ) {
          return `color : ${this.wsDARKER}`
        }
      }

      return `color : ${this.wsDARKER}`

    },
    categoryAction(category) {

      if ( category.sub_categories && category.sub_categories.length > 0 ) {
        category.expand = !category.expand
      }  else {
        this.selectedCategory = category
        this.topic = {}
        this.$router.push(this.localeLink('documentation/' + this.project ))
      }


    },

    async initPage() {
      let result = await this.GET_CATEGORIES_PUBLIC(this.project)
      if (!result || result === true) {
        return
      }
      result.forEach(item => {
        item.expand = false
      })
      this.categories = JSON.parse(JSON.stringify(result))

      if (this.alias ) {
        await this.getTopic(this.alias)
        let category = this.categories.find(el => el.value === this.topic.category)
        if ( category ) {
          this.selectedCategory = category
        }
        return
      }

      if ( this.selectedCategory.value ) {
        let category = this.categories.find(el => el.value === this.selectedCategory.value)
        if ( category ) {
          this.selectedCategory = category
        }
        return
      }
      if ( this.categories[0].sub_categories.length > 0 ) {

        this.selectedCategory = this.categories[0].sub_categories[0]
        this.categories[0].expand = true
        this.categories = JSON.parse(JSON.stringify(this.categories))
      } else {
        this.selectedCategory = this.categories[0]
      }

    },

  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

h4 {
  font-size: 14px !important;
}

.section_first {
  background-image: url('~@/assets/img/bg-pages.jpg');
  background-position: center center;
  position: relative;
  background-size: cover;
}


</style>